<template>
  <v-container>
    <v-card outlined class="my-2">
      <v-card-title>
        {{ header }}
      </v-card-title>
      <v-card-subtitle class="text-left">
        <div v-for="{ url, text, id } in items" :key="id">
          <a :href="url" target="_blank">{{ text }}</a>
        </div>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import uniqid from "uniqid";

export default {
  props: ["section"],
  computed: {
    header() {
      if (this.$store.state.onlineResources.length == 0) return "";
      return this.$store.state.onlineResources[this.section].header;
    },

    items() {
      if (this.$store.state.onlineResources.length == 0) return [];
      return this.$store.state.onlineResources[this.section].links.map(
        (item) => {
          item.id = uniqid();
          return item;
        }
      );
    },
  },
};
</script>
