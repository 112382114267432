<template>
  <v-navigation-drawer permanent dark app v-if="$vuetify.breakpoint.mdAndUp">
    <v-list dense>
      <v-list-item></v-list-item>
      <v-list-item-group v-model="section">
        <v-list-item
          v-for="(header, index) in headers"
          :key="`or-header-${index}`"
          @click="$emit('set-section', index)"
        >
          <v-list-item-content>
            <div class="text-left">
              {{ header }}
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
  <div v-else>
    <v-bottom-sheet dark v-model="sheet">
      <v-list dense>
        <v-list-item-group v-model="section">
          <v-list-item
            v-for="(header, index) in headers"
            :key="`or-header-${index}`"
            @click="$emit('set-section', index)"
          >
            <v-list-item-content>
              <div class="text-left">
                {{ header }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { uniq } from "lodash";

export default {
  props: ["showSheet"],
  data: () => ({
    section: 0,
  }),

  computed: {
    headers() {
      return uniq(this.$store.state.onlineResources.map((item) => item.header));
    },

    sheet: {
      get() {
        return this.showSheet;
      },
      set(value) {
        this.$emit("update-sheet", value);
      },
    },
  },
};
</script>
