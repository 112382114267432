<template>
  <main-container>
    <page-sizer>
      <v-container>
        <v-toolbar>
          <v-toolbar-title>
            Online Resources
          </v-toolbar-title>
        </v-toolbar>
      </v-container>
      <online-resource-headers
        :showSheet="showSheet"
        @set-section="setSection"
        @update-sheet="updateSheet"
      ></online-resource-headers>
      <online-resource-items :section="section"></online-resource-items>
      <v-btn dark fixed bottom left class="mb-5" @click="showSheet = true">
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
    </page-sizer>
  </main-container>
</template>

<script>
import MainContainer from "../components/MainContainer.vue";
import OnlineResourceHeaders from "../components/OnlineResources/OnlineResourceHeaders.vue";
import OnlineResourceItems from "../components/OnlineResources/OnlineResourceItems.vue";
import PageSizer from "../components/PageSizer.vue";

export default {
  components: {
    OnlineResourceHeaders,
    OnlineResourceItems,
    MainContainer,
    PageSizer,
  },
  data: () => ({
    showSheet: false,
    section: 0,
  }),
  methods: {
    setSection(section) {
      this.showSheet = false;
      this.section = section;
    },

    updateSheet(value) {
      this.showSheet = value;
    },
  },
};
</script>
